import React from "react";
import { AboutUs, Banner, LatestArticles, Services, Testimonial } from "../components";

const Home = () => {
  return (
    <>
      <Banner />
      <Services />
      {/* <LatestWorks /> */}
      <AboutUs />
      <LatestArticles />
      <Testimonial />
    </>
  );
};

export default Home;

import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { Placeholder } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useParams } from 'react-router-dom'
import { getProjectBySlug } from '../services'

export default function Projects() {
    const { slug } = useParams()

    const { isSuccess, data, isFetching } = useQuery({
        queryKey: ['ProjectView', slug], queryFn: async () => {
            return await getProjectBySlug(slug)
        }
    })
    const project = data?.data?.data[0]
    return (
        <div>
            {
                isFetching ?
                    <div className='blog-container py-3'>
                        <Placeholder className="rounded" style={{ minHeight: 450 }} xs={12} size="lg" />
                        <Placeholder className="rounded mt-2" style={{ minHeight: 30 }} xs={12} size="md" />
                        <Placeholder xs={12} size="sm" />
                    </div>
                    : isSuccess ? (
                        <>
                            <div className='blog-container'>
                                <LazyLoadImage width={'100%'} effect="blur" src={project.image.url} className='w-100 my-3 rounded banner-image' />
                                <h3 className='section_title text-danger'>{project.title}</h3>
                                <p className='text-primary text-justify'>{project.description}</p>
                            </div>
                        </>
                    ) : null
            }
        </div>
    )
}
